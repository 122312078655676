var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                    _c("i", {
                      staticClass: "mdi mdi-alert-circle-outline mr-2"
                    }),
                    _vm._v("Les champs obligatoires sont marqués avec (*) ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Désignation *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.roleForm.name,
                              expression: "roleForm.name"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.roleForm.name.$error
                          },
                          attrs: {
                            id: "projectname",
                            type: "text",
                            placeholder: ""
                          },
                          domProps: { value: _vm.roleForm.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.roleForm,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted && _vm.$v.roleForm.name.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.roleForm.name.required
                                ? _c("span", [
                                    _vm._v(
                                      "La référence du contrat est obligatoire."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Description")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.roleForm.description,
                              expression: "roleForm.description"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "projectname",
                            type: "text",
                            placeholder: ""
                          },
                          domProps: { value: _vm.roleForm.description },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.roleForm,
                                "description",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary float-right",
                          attrs: { disabled: _vm.$v.roleForm.$invalid }
                        },
                        [
                          _c("i", { staticClass: "fas fa-save" }),
                          _vm._v(" Enregistrer ")
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }